
import { Component, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { mixins } from 'vue-class-component';
import tasqsListModule from '@/store/modules/tasqsListModule';
import padSignalsModule from '@/store/modules/padSignalsModule';
import { getComponent, getConfigEnv, sleep } from '@/utils/helpers';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import DataLoading from '@/lib/mixins/dataLoading';
import assetsModule from '@/store/modules/assetsModule';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import workflowModule from '@/store/modules/workflowModule';
import TasqLightningChart from './PadSignalLightningChart.vue';
import TasqLightningProductionChart from './TasqLightningProductionChart.vue';
import GenericMixin from '@/lib/mixins/GenericMixin';
import { Debounce } from 'vue-debounce-decorator';

@Component({
  components: {
    TasqLoader: () => getComponent('loaders/TasqLoader'),
    PadSignalChart: () => getComponent('tasqs/PadSignalLightningChart'),
    ChartHeader: () => getComponent('tasqs/charts/ChartHeader'),
  },
})
export default class PadSignalsView extends mixins(DataLoading, GenericMixin) {
  @Prop({ type: String, required: false, default: '' }) filter?: string;

  @Prop({ type: String, required: false, default: '' }) title?: string;

  @Prop({ type: Boolean, required: false, default: false }) showLoadBtn?: boolean;

  chartTimes: any[] = this.$getConst('CHART_TIMES');

  signalChartTime = 60;

  chartRefreshKey = 0;

  hideChartTimes = false;

  legendRefreshKey = 0;

  showFullScreenSignalsChart = false;

  isLoadingSignals = true;

  chartsLoading = false;

  get tasqListLevel() {
    if (
      tasqsListModule.tasqListLevel.toLowerCase() == 'well' ||
      (this.activeTasq &&
        tasqsListModule.tasqListLevel.toLowerCase() !== 'wellview' &&
        this.activeTasq.level.toLowerCase() == 'well')
    ) {
      return 'Well';
    }
    return tasqsListModule.tasqListLevel.toLowerCase();
  }

  get activeTasq() {
    if (tasqsListModule.tasqListLevel.toLowerCase() === 'workticket') {
      console.log(tasqsListModule.activeWorkTicket);
      return tasqsListModule.activeWorkTicket;
    }
    if (this.isEditing || tasqsListModule.checkedTasqs.length) {
      if (!tasqsListModule.activeTasq) {
        tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
      }
      return tasqsListModule.activeTasq as TasqJob;
    }
    if (this.$route.query.type == 'id' && this.$route.params.id != null) {
      assetsModule.setActiveProducingTasq('');
      return tasqsListModule.activeTasq as TasqJob;
    }
    if (
      tasqsListModule.activeTasq != null &&
      this.$route.params.id != null &&
      this.$route.query.type == 'producing' &&
      tasqsListModule.activeTasq.level.toLowerCase() == 'pad'
    ) {
      return tasqsListModule.activeTasq;
    }
    if (assetsModule.activeTasq == undefined) {
      return tasqsListModule.activeTasq as TasqJob;
    }
    return assetsModule.activeTasq as TasqJob;
  }

  get isEditing() {
    return tasqsListModule.isEditing;
  }

  didSelectResetChart(type) {
    if (type == 'tasq-lightning-chart') {
      (this.$refs.padSignal as TasqLightningChart).didSelectResetChart();
    } else if (type == 'tasq-lightning-chart-full') {
      (this.$refs.padSignalFull as TasqLightningChart).didSelectResetChart();
    }
  }




  isSignalSelected(signalName) {
    if (this.filter === 'padProduction') {
      return (
        padSignalsModule[this.selectedSignalType].findIndex((name) => name.includes(signalName)) >
        -1
      );
    }
    return padSignalsModule[this.selectedSignalType].indexOf(signalName) > -1;
  }

  getCurrentSignalNameForLegend(signalName) {
    if (signalName == 'gas_rate') {
      return 'Gas Rate';
    }
    if (signalName == 'oil_rate') {
      return 'Oil Rate';
    }
    if (signalName == 'water_rate') {
      return 'Water Rate';
    }
    return signalName;
  }

  updateChartToMatchLegend() {
    if (this.showFullScreenSignalsChart) {
    } else {
      (this.$refs.padSignal as TasqLightningChart).updateChartToMatchLegend();
      console.log('Doing a full reset for main page hopefully');
    }
  }

  didSelectSignalOnLegend(index, signalName) {
    if (this.filter === 'padProduction') {
      this.didSelectPadProductionSignalOnLegend(index, signalName);
      return;
    } else if (this.showLoadBtn) {
      if (
        this.currentSelectedSignals.length === 4 &&
        !this.currentSelectedSignals.includes(signalName)
      ) {
        this.$tasqAlert({
          title: 'Error',
          message: 'At most, only four signals can be selected simultaneously.',
          type: 'error',
        });
        return;
      }
      padSignalsModule.selectSignal({
        signalName,
        selectedType: this.selectedSignalType,
      });
    } else {
      if (!this.showFullScreenSignalsChart) {
        (this.$refs.padSignal as TasqLightningChart).initializePage(index, signalName);
        padSignalsModule.selectSignal({
          signalName,
          selectedType: this.selectedSignalType,
        });
        this.legendRefreshKey += 1;
      } else {
        (this.$refs.padSignalFull as TasqLightningChart).initializePage(index, signalName);
        padSignalsModule.selectSignal({
          signalName,
          selectedType: this.selectedSignalType,
        });
        this.legendRefreshKey += 1;
      }
    }
  }

  didSelectPadProductionSignalOnLegend(index, signalName, setType = true) {
    if (setType) {
      if (signalName === this.individualProductionType) {
        return;
      }
      padSignalsModule.setIndividualProductionType(signalName);
      if (!this.showFullScreenSignalsChart) {
        (this.$refs.padSignal as TasqLightningChart).initializeProductionPage(index, signalName);
        padSignalsModule.selectPadProductionSignal({
          signalName,
          selectedType: this.selectedSignalType,
          currentType: this.signalType,
        });
        this.legendRefreshKey += 1;
      } else {
        (this.$refs.padSignalFull as TasqLightningChart).initializeProductionPage(
          index,
          signalName
        );
        padSignalsModule.selectPadProductionSignal({
          signalName,
          selectedType: this.selectedSignalType,
          currentType: this.signalType,
        });
        this.legendRefreshKey += 1;
      }
    } else {
      if (!this.showFullScreenSignalsChart) {
        (this.$refs.padSignal as TasqLightningChart).initializePage(index, signalName);
        padSignalsModule.selectSignal({
          signalName,
          selectedType: this.selectedSignalType,
        });
        this.legendRefreshKey += 1;
      } else {
        (this.$refs.padSignalFull as TasqLightningChart).initializePage(index, signalName);
        padSignalsModule.selectSignal({
          signalName,
          selectedType: this.selectedSignalType,
        });
        this.legendRefreshKey += 1;
      }
    }
  }

  didSelectSignalsFullScreen() {
    this.showFullScreenSignalsChart = !this.showFullScreenSignalsChart;
    this.updateChartToMatchLegend();
  }

  loadSelectedSignals() {
    if (this.currentSelectedSignals.length === 0) {
      this.$tasqAlert({
        title: 'Error',
        message: 'Please make sure to select at least one signal in order to load the data.',
        type: 'error',
      });
      return;
    }
    this.$emit('load-selected-signals', this.currentSelectedSignals);
  }

  get isProductionDataLoaded() {
    return tasqProductionDataChartModule.productionData.loaded;
  }

  get currentWellType(): any {
    return this.$route.query.type;
  }

  get signalType() {
    return `${this.filter}Signals`;
  }

  get selectedSignalType() {
    return `${this.filter}SelectedSignals`;
  }

  get individualProductionType() {
    return padSignalsModule.individualProductionType;
  }

  get currentSignals(): any {
    return padSignalsModule[this.signalType];
  }

  get currentSelectedSignals(): any {
    return padSignalsModule[this.selectedSignalType];
  }

  get legendSignals(): any {
    let legendSignals: any = [];

    if (this.filter !== 'padProduction') {
      for (let x = 0; x < this.currentSignals.length; x++) {
        const currentSignal = this.currentSignals[x];
        if (
          currentSignal.name.includes('Lower') ||
          currentSignal.name.includes('Upper') ||
          currentSignal.name.includes('Target')
        ) {
          continue;
        }
        legendSignals.push({
          name:
            this.filter !== 'padProduction'
              ? this.currentSignals[x].name
              : this.currentSignals[x].legendName,
          selected: this.isSignalSelected(this.currentSignals[x].name),
          color: this.currentSignals[x].color,
          type: 'SIGNAL',
        });
      }
    } else {
      const types = [
        { name: 'Gas', color: '#f55d8b' },
        { name: 'Water', color: '#0077ff' },
        { name: 'Oil', color: '#2de6c1' },
      ];
      for (let x = 0; x < types.length; x++) {
        legendSignals.push({
          name: types[x].name,
          selected: this.isSignalSelected(types[x].name),
          color: types[x].color,
          type: 'SIGNAL',
        });
      }
    }

    return legendSignals;
  }

  get legendWellSignalNames(): any {
    let legendSignals: any = [];
    const currentSignals = this.currentSignals.filter(
      (signal) => signal.legendName === this.individualProductionType
    );
    for (let x = 0; x < currentSignals.length; x++) {
      legendSignals.push({
        name: currentSignals[x].name,
        selected: this.isSignalSelected(currentSignals[x].name),
        wellName: currentSignals[x].wellName,
        color: currentSignals[x].color,
        type: 'SIGNAL',
      });
    }
    return legendSignals;
  }

  timer;

  @Debounce(1000)
  handleScroll() {
    console.log('object');
    if ((this.$refs.padSignal as TasqLightningChart) != null) {
      (this.$refs.padSignal as TasqLightningChart).reloadChartOnScroll();
    }

    if (this.timer !== null) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      if ((this.$refs.padSignal as TasqLightningChart) != null) {
        (this.$refs.padSignal as TasqLightningChart).refresh();
      }
    }, 150);
  }
}
